<template>
  <div class='base-goods-page'>
    <div class="container" v-if="!loading && product">
      <BaseBread>
        <BaseBreadItem to="/">首页</BaseBreadItem>
        <BaseBreadItem to="/product">产品中心</BaseBreadItem>
        <BaseBreadItem>产品详情</BaseBreadItem>
      </BaseBread>
      <div class="product-info">
        <div class="spec">
          <ProductName :product="product"/>
          <div class="media" v-if="product.image">
            <ProductImage :images="product.image"/>
          </div>
          <div class="video-play" v-if="product.videoFilePath">
            <BaseVideoPlayer :options="videoOption"></BaseVideoPlayer>
          </div>
          <div>
            <div class="content-box" v-html="product.content"></div>
            <div v-if="product.isPicture === 1" class="content-box" style="width: 100%;">
              <div v-for="(item, index) in product.contentImage" :key="index" style="width: 100%;">
                <img :src="item.filePath" style="width: 100%;" :alt="product.productName"></div>
            </div>
          </div>
        </div>
        <!--        <div class="goods-footer">-->
        <!--          <div class="goods-aside">-->
        <!--            <GoodsHot />-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import ProductImage from "@/views/product/components/product-image.vue";
import ProductName from "@/views/product/components/product-name.vue";
import ProductHot from "@/views/product/components/product-hot.vue";
import {nextTick, provide, ref, watch, reactive} from 'vue'
import {findProducts} from '@/api/product'
import {useRoute} from 'vue-router'
import BaseBread from "@/components/library/base-bread.vue";
import BaseBreadItem from "@/components/library/base-bread-item.vue";
import BaseVideoPlayer from "@/components/library/base-video-player.vue";

export default {
  name: 'ProductDetail',
  components: {BaseVideoPlayer, BaseBreadItem, BaseBread, ProductImage, ProductName, ProductHot},
  setup() {
    const {product, loading, videoOption} = useProduct()
    // 提供product数据给后代组件使用
    provide('product', product)

    return {product, loading, videoOption}
  }
}
// 获取产品详情
const useProduct = () => {
  // 出现路由地址产品ID发生变化，但是不会重新初始化组件
  const product = ref(null)
  const route = useRoute()
  const loading = ref(false)
  const videoOption = ref(null)
  watch(() => route.params.id, (newVal) => {
    if (newVal && `/product/detail/${newVal}` === route.path) {
      loading.value = true
      findProducts(route.params.id).then(data => {
        // 让产品数据为null然后使用v-if的组件可以重新销毁和创建
        product.value = null
        nextTick(() => {
          product.value = data.data.detail
          loading.value = false
        })
        videoOption.value = {
          autoplay: true,
          controls: true,
          sources: [
            {
              src: data.data.detail.videoFilePath,
              type: "video/mp4",
            },
          ],
          width: "870px",
          height: "490px",
        };

      })
    }
  }, {immediate: true})
  return {product, loading, videoOption}
}
</script>

<style scoped lang='less'>
.loading {
  height: 580px;
  width: 1240px;
  margin: 72px auto 20px;
  background: rgba(255, 255, 255, .9) url(../../../assets/images/loading.gif) no-repeat center;
}

.product-info {
  min-height: 600px;
  background: #fff;
  display: flex;

  .media {
    margin-left: 150px;
    width: 580px;
    height: 500px;
    padding: 30px 50px;
  }

  .spec {
    flex: 1;
    padding: 30px 30px 30px 0;
  }
}

.video-play {
  width: 73.5%;
  margin: 50px 50px 0 20vh;
  border: 10px solid #e4e4e4;
  display: grid;
  justify-content: center;
  align-items: center;
}

.content-box {
  width: 100%;
  padding: 50px;
}

// .goods-tabs {
//   min-height: 600px;
//   background: #fff;
// }
// .goods-warn {
//   min-height: 600px;
//   background: #fff;
//   margin-top: 20px;
// }
</style>
